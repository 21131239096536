import React from 'react'

import { DividerStyles } from './styles'

export type TDivider = 'dashed' | 'solid' | 'double' | 'dotted'

export interface DividerProps extends React.HTMLAttributes<unknown> {
  type?: TDivider
  borderWidth?: number
  fullScreen?: boolean
  color?: string
}
const Divider = ({
  type = 'dashed',
  borderWidth,
  color = 'var(--gray200)',
  ...props
}: DividerProps) => <DividerStyles type={type} borderWidth={borderWidth} color={color} {...props} />

export default Divider

import React from 'react'
import '@inter/inter-ui/inter-ui.css'

import { BrowserRouter } from 'react-router-dom'
import Routers from 'Routes'

import { initTheme, Theme } from '@inter/inter-ui'

function App() {
  React.useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  return (
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
  )
}

export default App

import SuperLimites from 'pages/SuperLimites'
import { Navigate, Route, Routes } from 'react-router-dom'

const Routers = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/super-limite" />} />
    <Route path="/super-limite" element={<SuperLimites />} />
  </Routes>
)

export default Routers

import * as React from 'react'

import { interWbNavigate } from 'inter-webview-bridge'
import { bridgeNative } from 'utils/constants'

interface GoBackProps {
  goBackNative?: boolean
}

const useGoBack = ({ goBackNative }: GoBackProps) => {
  const goBack = React.useCallback(() => {
    if (goBackNative) {
      interWbNavigate.requestGoBack()
    }
  }, [goBackNative])

  React.useEffect(() => {
    bridgeNative.addBackListener(goBack)
    return () => {
      bridgeNative.removeBackListener(goBack)
    }
  }, [goBack])

  return goBack
}

export default useGoBack

import styled from 'styled-components'

import { DividerProps } from '.'

export const DividerStyles = styled.div<DividerProps>`
  border-top: ${(props) => `${props.borderWidth || 1}px ${props.type} ${props.color}`};
  margin: 13px 0px;
  border-bottom: transparent;
  border-left: transparent;
  border-right: transparent;
`

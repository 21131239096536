import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Inter", sans-serif;
  }
  
  body, html, #root,  {
    height: 100%;
  }


`

export const AppContainer = styled.div<{ fullHeight?: boolean; withAppBarHeight?: boolean }>`
  padding: 16px;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
`

export const SpaceBetween = styled.div<{
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
}>`
  ${({ direction }) => (direction === 'row' ? { width: '100%' } : { height: '100%' })}
  display: flex;
  flex-direction: ${({ direction = 'column' }) => direction};
  justify-content: space-between;
`

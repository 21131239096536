import Divider from 'components/Divider'
import SuperLimitImg from 'assets/imgs/card-pessoa.webp'
import CashbackIcon from 'assets/icons/cashback.svg'
import MoneyIcon from 'assets/icons/moneyUp.svg'
import CashbackSuperLimiteImg from 'assets/imgs/cashback-super-limite.webp'
import { AppContainer } from 'App.styles'
import useGoBack from 'hooks/useGoBack'
import { interWbSession } from 'inter-webview-bridge'
import { ETrackEvent } from 'enuns/ETrackEvent'

import { Text } from '@inter/inter-ui/components/Text'
import { Button } from '@inter/inter-ui/components/Button'
import { Spacing } from '@inter/inter-ui/components/Spacing'

import { Icon, ImgStyled, UlStyled } from './styles'

const SuperLimites = () => {
  const goBack = useGoBack({ goBackNative: true })

  const handleClickButton = async () => {
    try {
      goBack()
      await interWbSession.requestAnalytics(ETrackEvent.SUPER_LIMITE_APROVEITAR)
    } catch (error) {
      window.console.log(error)
    }
  }

  return (
    <>
      <AppContainer style={{ padding: '24px 0 0 0' }}>
        <ImgStyled src={SuperLimitImg} alt="Super limites" sizes="70vmin" />
      </AppContainer>
      <AppContainer>
        <Spacing my="xs">
          <Text variant="headline-h1" as="h1" semiBold>
            Finalize suas compras com limite extra
          </Text>
        </Spacing>
        <Text variant="body-3" as="p" color="typography">
          Um limite extra no seu cartão de crédito para que você não deixe de comprar o produto que
          deseja, mesmo sem limite suficiente.
        </Text>
        <Spacing my="sm">
          <Divider />
        </Spacing>
        <Spacing mb="xxs">
          <Text variant="headline-h3" as="h3" semiBold>
            Super Limite ao invés de cashback
          </Text>
        </Spacing>
        <Text variant="body-3" as="p" color="typography">
          Ao comprar com limite extra no seu cartão de crédito você não ganha cashback na compra.
        </Text>
      </AppContainer>
      <AppContainer>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <div style={{ textAlign: 'center', flex: '1' }}>
            <Icon src={CashbackIcon} alt="Cashback" />
            <Text variant="headline-h3" as="h3" semiBold style={{ color: 'var(--success500)' }}>
              Cashback na compra
            </Text>
          </div>
          <div style={{ flex: '0.4', textAlign: 'center' }}>
            <Text variant="body-3" as="p" bold style={{ fontSize: 16, color: 'var(--primary500)' }}>
              OU
            </Text>
          </div>
          <div style={{ textAlign: 'center', flex: '1' }}>
            <Icon src={MoneyIcon} alt="Super limite" />
            <Text variant="headline-h3" as="h3" semiBold color="primary">
              Super Limite para finalizar sua compra
            </Text>
          </div>
        </div>
      </AppContainer>
      <AppContainer>
        <Spacing mb="xs">
          <Text variant="headline-h3" as="h3" semiBold>
            O Super Limite fica disponível caso:
          </Text>
        </Spacing>
        <UlStyled>
          <li>
            <Text variant="body-3" as="p" color="typography">
              Seu limite de cartão de crédito não for suficiente para finalizar a compra.
            </Text>
          </li>
          <li>
            <Text variant="body-3" as="p" color="typography">
              Sua compra for de até R$ 15.000.
            </Text>
          </li>
          <li>
            <Text variant="body-3" as="p" color="typography">
              Você não utiliza o CDB Mais Limite ou o Poupança Mais Limites.
            </Text>
          </li>
          <li>
            <Text variant="body-3" as="p" color="typography">
              Estiver comprando diretamente no app sem redirecionamento para lojas parceiras.
            </Text>
          </li>
        </UlStyled>
      </AppContainer>
      <AppContainer style={{ paddingTop: 0 }}>
        <Spacing mb="xs">
          <Text variant="headline-h3" as="h3" semiBold>
            Qual será o valor do Super Limite?
          </Text>
        </Spacing>
        <Text variant="body-3" as="p" color="typography">
          O limite extra será 5x o valor do cashback que seria dado para aquele produto, caso não
          tivesse ativado o Super Limite. Em carrinhos com mais de um produto, basta somar os
          limites extras de cada um.
        </Text>
        <Spacing
          my="xs"
          style={{
            textAlign: 'center',
          }}
        >
          <ImgStyled
            src={CashbackSuperLimiteImg}
            alt="Cashback Super Limite"
            style={{ width: '30vh' }}
          />
        </Spacing>
        <Spacing mb="xs">
          <Text variant="body-3" as="p" bold>
            O Super Limite da compra será no máximo:
          </Text>
        </Spacing>
        <UlStyled>
          <li>
            <Text variant="body-3" as="p" color="typography">
              Metade do valor total do produto.
            </Text>
          </li>
          <li>
            <Text variant="body-3" as="p" color="typography">
              Menor ou igual ao seu limite original.
            </Text>
          </li>
        </UlStyled>
      </AppContainer>

      <Divider type="solid" color="var(--gray100)" />
      <AppContainer>
        <Button style={{ width: '100% ' }} onClick={handleClickButton}>
          Aproveitar no Inter Shop
        </Button>
      </AppContainer>
    </>
  )
}

export default SuperLimites

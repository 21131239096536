import styled from 'styled-components'

export const UlStyled = styled.ul`
  margin-left: 20px;

  h3 {
    margin-bottom: 8px;
  }

  li {
    margin: 8px 0;
  }

  li::marker {
    font-size: 15px;
    vertical-align: middle;
    line-height: 20px;
    color: var(--gray400);
  }
`

export const ImgStyled = styled.img`
  width: 100%;
`

export const Icon = styled.img`
  width: 85px;
`
